import { createTheme } from '@mui/material';
import { breakpoints } from './Breakpoints';
import components from './Components';
import palette from './Palette';
import { typography } from './Typography';

export enum OptionsDatepicker {
	Margin = 'margin',
	Field = 'field',
}

export const theme = createTheme({
	palette,
	typography,
	breakpoints,
	zIndex: {},
	transitions: {},
	components,
	datepicker: OptionsDatepicker.Margin,
});

declare module '@mui/material/styles' {
	interface BreakpointOverrides {
		xs: false;
		sm: true;
		md: true;
		lg: true;
		xl: false;
	}
}

declare module '@mui/material/Button' {
	interface ButtonPropsVariantOverrides {
		link: true;
		linkDark: true;
		home: true;
		outlinedDark: true;
		underline: true;
		upload: true;
	}
}

declare module '@mui/material/Paper' {
	interface PaperPropsVariantOverrides {
		light: true;
		dialog: true;
		uploadFileDialog: true;
		deleteClaimDialog: true;
		confirm: true;
		service: true;
		payment: true;
	}
}

declare module '@mui/material/styles' {
	interface Theme {
		datepicker: string;
	}

	interface ThemeOptions {
		datepicker: string;
	}
}

declare module '@mui/material/TextField' {
	interface TextFieldPropsVariantOverrides {
		eye: true;
	}

	interface TextFieldPropsVariantsOptions {
		eye: string;
	}
}

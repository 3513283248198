import { claimStatusIds } from './Claims';

export interface IClaimState {
	errorCode: number;
	errorMessage: string;
	triggerRefresh: boolean;
	calculated: boolean;
	redirect: boolean;
	claimData: IClaim;
	statusCalculation: TStatusCalculation;
	servicesMap: IService[];
	prescriptionSelectedFromStorage: boolean;
	doctorTypes: { name: string; label: string }[];
	reasonCodesVoid: IReasonCode[];
	reasonCodesCanceled: IReasonCode[];
	otherServices: { serviceLabel: string; serviceId: number | null }[];
	validationErrors: IValidationError[];
}

interface IReasonCode {
	code: string;
	groupCode: string;
	groupLabel: string;
	label: string;
	typologyCode: string;
	typologyLabel: string;
}

interface IServiceGroup {
	code: string;
	label: string;
}

export interface IService {
	code: string;
	label: string;
	group: IServiceGroup;
}

export type TStatusCalculation =
	| 'beforeCalculation'
	| 'afterCalculation'
	| 'onEdit';

export interface IClaim {
	claimId?: number;
	claimUUID: string;
	externalClaimId: string;
	memberId: string;
	providerId: string;
	beneficiaryMemberId: string;
	beneficiarySubscriptionId: number;
	beneficiarySubscriptionUUID: string;
	memberSubscriptionId: number;
	claimDate: string;
	step: number;
	// page 1
	exam: number;
	materials: number;
	lensType?: number;
	//page 2
	newPrescriptionContent?: IPrescription;
	oldPrescriptionContent?: IPrescription;
	prescriptionConsent: boolean;
	rxChangedWithoutOldPrescription: boolean | null;
	//page 3
	amount: {
		//basically everything is passed by calculate. only serviceid and retail amount is not
		components: IClaimComponents[];
		total: IClaimComponents;
		accumulator?: IClaimAccumulator;
	};
	rxChangeAttestation?: 0 | 1;
	uploadConsent: boolean;
	attachments: {
		prescription?: IFileType;
		receipt?: IFileType;
		others: IFileType[];
	};
	//useless
	memberFullName?: string;
	beneficiaryFullName?: string;
	memberFirstName?: string;
	memberLastName?: string;
	groupName?: string;
	memberProviderLocation?: string;
	// claimStatusId?: 1 | 2 | 3 | 4 | 5 | 13 | 14; // deprecated, TODO: delete in a couple of releases
	// claimStatus?: string; // deprecated, TODO: delete in a couple of releases
	reasonCode: string;
	status: {
		id: claimStatusIds;
		name: string;
		label: string;
		groupCode: string;
		groupLabel: string;
	};
	planPayAmount?: number;
	memberPayAmount?: number;
	paidWith?: string;
	// memberDesignation?: string;
	memberDesignationLabel?: string;
	planName?: string;
	oonProviderInfo?: {
		providerName?: string;
		providerLocation?: string;
		providerPhone?: string;
	};
	reimbursementInfo?: {
		ibanAccount?: string;
		bic?: string;
		bankName?: string;
		bankAddress?: string;
		beneficiaryName?: string;
	};
	signatureId?: number;
	servicesList?: string[];
}

export type IClaimComponents = {
	retailAmount: number | string;
	total?: number;
	contractualAdjustmentAmount?: number;
	memberPayAmount?: number;
	planPayAmount?: number;
	serviceName?: string;
	serviceId: null | number;
	discountedAmount?: number;
	assessment?: number;
	allowablePlanPay?: number;
	coPay?: number;
	coInsurance?: number;
	disallowedAmount?: number;
	decliningBalance?: number;
	claimCostReduction?: number;
	inNetworkSave?: number;
	grossRetail?: number | string;
	inStorePromotion?: number | string;
};

export type IPrescription = {
	ava: number;
	prysm: number;
	decentralization: number;
	od: IEyePrescription;
	os: IEyePrescription;
	doctorTypology: string | null;
	prescriptionDate: string | null;
};

export type IEyePrescription = {
	sphere?: string;
	cylinder?: string;
	axis?: string;
	addition?: string;
	prismDioptresIO?: string;
	prismDirectionIO?: string;
	prismDioptresUD?: string;
	prismDirectionUD?: string;
	pd?: string;
	height?: string;
	vertexFitted?: string;
	faceformTilt?: string;
	pantoscopicTilt?: string;
};

type IClaimAccumulator = {
	benefitId: number;
	benefitType: string;
	accumulatorId: number;
	balance: number;
	maxUsableAmount: number;
	benefitUsed: number;
	planId: number;
	startDate: Date;
	endDate: Date;
	accumulatorType: string;
};

export type IFileType = {
	id: number;
	fileName?: string;
};

export enum Eye {
	od = 'od',
	os = 'os',
}

export const PRESCRIPTION_DEFAULT = {
	ava: 0,
	prysm: 0,
	decentralization: 0,
	doctorTypology: null,
	prescriptionDate: null,
	od: {
		sphere: '',
		cylinder: '',
		axis: '',
		addition: '',
		prismDioptresIO: '',
		prismDirectionIO: '',
		prismDioptresUD: '',
		prismDirectionUD: '',
		pd: '',
		height: '',
		vertexFitted: '',
		faceformTilt: '',
		pantoscopicTilt: '',
	},
	os: {
		sphere: '',
		cylinder: '',
		axis: '',
		addition: '',
		prismDioptresIO: '',
		prismDirectionIO: '',
		prismDioptresUD: '',
		prismDirectionUD: '',
		pd: '',
		height: '',
		vertexFitted: '',
		faceformTilt: '',
		pantoscopicTilt: '',
	},
};

export const SERVICES_TYPE = {
	LENSES_ONLY: 1,
	FRAME_ONLY: 2,
	EXAM: 3,
	FRAME_AND_LENSES: 4,
	CONTACTS: 5,
};

export const optionsExam: {
	label: string;
	labelCap?: string;
	value: number;
	enableIf: number[];
}[] = [
	{
		label: 'newClaim.exam.RoutineExam',
		value: SERVICES_TYPE.EXAM,
		enableIf: [SERVICES_TYPE.EXAM],
	},
	{
		label: 'newClaim.exam.NoExam',
		value: -1,
		enableIf: [
			SERVICES_TYPE.LENSES_ONLY,
			SERVICES_TYPE.FRAME_AND_LENSES,
			SERVICES_TYPE.CONTACTS,
			SERVICES_TYPE.FRAME_ONLY,
		],
	},
];

export const optionsMaterials: {
	label: string;
	labelCap?: string;
	value: number;
	enableIf: number[];
}[] = [
	{
		label: 'newClaim.materials.fl',
		labelCap: 'newClaim.materials.flCapitalized',
		value: SERVICES_TYPE.FRAME_AND_LENSES,
		enableIf: [SERVICES_TYPE.FRAME_AND_LENSES],
	},
	{
		label: 'newClaim.materials.fo',
		value: SERVICES_TYPE.FRAME_ONLY,
		enableIf: [SERVICES_TYPE.FRAME_ONLY, SERVICES_TYPE.FRAME_AND_LENSES],
	},
	{
		label: 'newClaim.materials.lo',
		value: SERVICES_TYPE.LENSES_ONLY,
		enableIf: [SERVICES_TYPE.LENSES_ONLY, SERVICES_TYPE.FRAME_AND_LENSES],
	},
	{
		label: 'newClaim.materials.contacts',
		value: SERVICES_TYPE.CONTACTS,
		enableIf: [SERVICES_TYPE.CONTACTS],
	},
	{
		label: 'newClaim.materials.noMat',
		value: -2,
		enableIf: [SERVICES_TYPE.EXAM],
	},
];

export const allBenefits = [
	{
		label: 'newClaim.priceEstimate.exam',
		value: SERVICES_TYPE.EXAM,
		enableIf: [SERVICES_TYPE.EXAM],
	},
	...optionsMaterials,
];
export interface IValidationError {
	field: number;
	message: string;
	value: string;
}

export type ReasonCode = {
	code: string;
	groupCode: string;
	groupLabel: string;
	label: string;
	typologyCode: string;
	typologyLabel: string;
};

export enum REASON_CODE_TYPOLOGY {
	VOID = 'void',
	CANCEL = 'cancel',
}

import { useCallback, useMemo } from 'react';
import { AppDispatch } from 'store/store';

import {
	DeleteClaimMappingPayload,
	setDialogDeleteClaimOpen,
	setInjectionState,
} from 'feature/hooks/deleteClaimSlice';
import { useDispatch } from 'react-redux';

const useDialogDeleteClaim = () => {
	const dispatch = useDispatch<AppDispatch>();

	const closeDialogDeleteClaim = useCallback(() => {
		dispatch(setDialogDeleteClaimOpen(false));
	}, [dispatch]);

	const handleOpen = useCallback(
		(payload: DeleteClaimMappingPayload) => {
			dispatch(setInjectionState(payload));
			dispatch(setDialogDeleteClaimOpen(true));
		},
		[dispatch],
	);

	return useMemo(
		() => ({
			open: handleOpen,
			close: closeDialogDeleteClaim,
		}),
		[handleOpen, closeDialogDeleteClaim],
	);
};

export default useDialogDeleteClaim;

import styled from 'styled-components';
import { claimStatusIds } from 'types/Claims';

interface Props {
	status: claimStatusIds;
}

export const StatusIcon = styled.span<Props>`
	height: 8px;
	width: 8px;
	background-color: ${props => {
		switch (props.status) {
			case 1:
			case 13:
				return '#B4B4B4';
			case 3:
			case 10:
			case 11:
			case 12:
				return '#E0A93E';
			case 4:
			case 5:
				return '#55A63A';
			case 2:
			case 7:
			case 8:
			case 14:
			case 15:
				return '#902727';
			case 9:
				return '#55A63A';
		}
	}};
	border-radius: 50%;
	display: inline-block;
	margin-right: 10px;
`;

import { useDispatch, useSelector } from 'react-redux';
import {
	Box,
	Checkbox,
	IconButton,
	Paper,
	Stack,
	Typography,
	UnstiledLink,
} from 'styles';
import { getShortDate } from 'utils/utils';

import { ReactComponent as ChevronRightIcon } from 'assett/icons/chevron_right_icon.svg';
import ButtonDeleteClaim from 'components/commons/Dialogs/deleteClaim/ButtonDeleteClaim';
import { setTriggerRefresh } from 'feature/claims/claimSlice';
import { setSelectOne } from 'feature/claims/claimsSlice';
import { DELETE_CLAIM_REDIRECT_TO } from 'feature/hooks/deleteClaimSlice';
import useDevice from 'hooks/DesktopDevice.hook';
import useDialogDeleteClaim from 'hooks/DialogDeleteClaim.hook';
import { FC } from 'react';
import { useNavigate } from 'react-router-dom';
import { AppDispatch, RootState } from 'store/store';
import { useTranslate } from 'stylesHooks';
import { IClaims } from 'types/Claims';
import { TRANSLATION_KEYS } from 'utils/constants';
import { StatusIcon } from './StatusIcon.component';

export const ClaimsList: FC = () => {
	const { t: tClientLabels } = useTranslate(TRANSLATION_KEYS.CLIENT_LABELS);
	const { t: tDbLabels } = useTranslate(TRANSLATION_KEYS.DB_LABELS);
	const { isDesktop } = useDevice();
	const navigate = useNavigate();
	const dispatch = useDispatch<AppDispatch>();
	const deleteClaimDialog = useDialogDeleteClaim();

	const { claims, claimIds } = useSelector((store: RootState) => store.claims);
	const progressIndicator = useSelector(
		(state: RootState) => state.progressIndicator,
	);
	const { features } = useSelector((store: RootState) => store.user);
	const featuresRole = features.map(el => el.code);

	const handleClick = (id: number) => {
		navigate(`/view-claims/${id}`);
	};

	const handleClickCheckAndSubmit = (
		memberFullName: string,
		claimId: number,
	) => {
		// trigger claim refresh to load the current claim data
		dispatch(setTriggerRefresh(true));
		navigate(`/check-and-submit/${memberFullName}/new-claim?claimId=${claimId}`);
	};

	const handleClickDeleteClaim = ({
		claimId,
		claimUUID,
	}: {
		claimId: number;
		claimUUID: string;
	}) => {
		const payload = {
			claimId,
			claimUUID,
			redirectTo: DELETE_CLAIM_REDIRECT_TO.LIST,
		};

		deleteClaimDialog.open(payload);
	};

	const List = claims.map((claim: IClaims) => {
		const isChecked = claimIds.includes(claim.claimId);
		const enableDelete = claim.status.id === 1; // status is Pending Documentation

		return (
			<Paper
				variant="outlined"
				sx={{
					borderWidth: 0,
					mb: 0.5,
					minWidth: 'fit-content',
					width: '100%',
				}}
				key={claim.claimId}
			>
				<Stack
					direction={'row'}
					alignItems={'center'}
					height={!!isDesktop ? 52 : 38}
					sx={{ p: !!isDesktop ? 1.25 : 0.8 }}
				>
					<Stack direction={'row'} alignItems={'center'}>
						<Checkbox
							label=""
							accessibilitylabel={tClientLabels('claim.labels.selectClaim')}
							key={claim.claimId}
							sx={{ p: 0, ml: 0 }}
							checked={isChecked}
							onClick={() => {
								dispatch(setSelectOne(claim.claimId));
							}}
						/>

						<Typography
							variant="body"
							sx={{ width: !!isDesktop ? 245 : 220, ml: 1.5 }}
						>
							{`#${claim.externalClaimId}`}
						</Typography>
						<Typography variant="body" sx={{ width: !!isDesktop ? 245 : 220 }}>
							{claim.memberFirstName}
						</Typography>
						<Typography variant="body" sx={{ width: !!isDesktop ? 245 : 220 }}>
							{claim.memberLastName}
						</Typography>
						<Typography variant="body" sx={{ width: !!isDesktop ? 336 : 220 }}>
							{getShortDate(claim.claimDate)}
						</Typography>

						{claim.status.id === 1 && featuresRole.includes('submit-claims') ? (
							<UnstiledLink
								variant="body"
								onClick={() => {
									handleClickCheckAndSubmit(claim.memberFullName, claim.claimId);
								}}
								sx={{ width: !!isDesktop ? 300 : 290 }}
							>
								<Typography variant="body">
									<StatusIcon status={claim.status.id} />
									{tDbLabels(claim.status.label)}
								</Typography>
							</UnstiledLink>
						) : (
							<Typography variant="body" sx={{ width: !!isDesktop ? 300 : 290 }}>
								<StatusIcon status={claim.status.id} />
								{tDbLabels(claim.status.label)}
							</Typography>
						)}
					</Stack>
					<Stack
						ml={'auto'}
						direction={'row'}
						gap={'30px'}
						justifyContent={'flex-end'}
					>
						{enableDelete ? (
							<ButtonDeleteClaim
								iconButton
								onClickCallback={() =>
									handleClickDeleteClaim({
										claimId: claim.claimId,
										claimUUID: claim.claimUUID,
									})
								}
							/>
						) : (
							// we have to add this empty box to align the delete icon
							<Box sx={{ width: 24, height: 24 }} />
						)}
						<IconButton
							onClick={() => handleClick(claim.claimId)}
							aria-label={tClientLabels('dashboard.buttons.viewClaims')}
						>
							<ChevronRightIcon />
						</IconButton>
					</Stack>
				</Stack>
			</Paper>
		);
	});

	return (
		<Stack sx={{ mb: 0.5, width: '100%' }}>
			{List.length > 0 ? (
				List
			) : (
				<Typography variant="bodyLink" sx={{ width: '100%', padding: 1.2 }}>
					{progressIndicator?.isLoading
						? ''
						: tClientLabels('view-claims.noClaimsFound')}
				</Typography>
			)}
		</Stack>
	);
};

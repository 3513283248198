import { useSelector } from 'react-redux';
import { RootState } from 'store/store';
import { Stack, Typography } from 'styles';
import { useTranslate } from 'stylesHooks';
import {
	SERVICES_GROUPS,
	SERVICES_SHORT_TRANSLATION_KEY,
	TRANSLATION_KEYS,
} from 'utils/constants';

const LegendForServiceType = () => {
	const { t: tDBLabels } = useTranslate(TRANSLATION_KEYS.DB_LABELS);
	const { t: tClientLabels } = useTranslate(TRANSLATION_KEYS.CLIENT_LABELS);
	const { servicesMap } = useSelector((state: RootState) => state.claim);

	const services = servicesMap.map(service => {
		const initial = tDBLabels(
			`${service.label}.${SERVICES_SHORT_TRANSLATION_KEY}`,
		);

		if (service.group.code === SERVICES_GROUPS.OTHERS) {
			const other = tClientLabels(service.group.label);
			const serviceLabel = tDBLabels(service.label);
			const itemLabel = tClientLabels('service.legend.otherItem', {
				initial,
				other,
				service: serviceLabel,
			});

			return itemLabel;
		}

		return tClientLabels('service.legend.item', {
			initial,
			service: tDBLabels(service.label),
		});
	});

	return (
		<>
			<Stack direction={'row'} alignItems={'center'}>
				<Typography variant="bodyLink" sx={{ whiteSpace: 'nowrap' }}>
					{tClientLabels('claim.legend.title')}
				</Typography>
				<Stack direction={'row'} ml={2} flexWrap={'wrap'} gap={2} rowGap={1}>
					{services.map((service, index) => (
						<Typography key={index} variant="body">
							{service}
						</Typography>
					))}
				</Stack>
			</Stack>
		</>
	);
};

export default LegendForServiceType;

import {
	setClaimExcludeIds,
	setClaimIds,
	setDownloadAll,
} from 'feature/claims/claimsSlice';
import {
	handleChange,
	handleChangeClaimStatus,
	setSearch,
} from 'feature/form/formSlice';
import { useDispatch, useSelector } from 'react-redux';

import { ReactComponent as SearchHideIcon } from 'assett/icons/search_hide_icon.svg';
import { ReactComponent as SearchIcon } from 'assett/icons/search_icon.svg';
import SelectGeneric from 'components/commons/form/SelectGeneric';
import HeaderCellWithSorting from 'components/commons/list/HeaderCellWithSorting';
import useDevice from 'hooks/DesktopDevice.hook';
import { FC } from 'react';
import { AppDispatch, RootState } from 'store/store';
import Box from 'styles/box/Box';
import IconButton from 'styles/button/IconButton';
import Checkbox from 'styles/checkbox/Checkbox';
import DatePicker from 'styles/datePicker/DatePicker';
import Stack from 'styles/stack/Stack';
import palette from 'styles/theme/Palette';
import { useTranslate } from 'stylesHooks';
import { TRANSLATION_KEYS } from 'utils/constants';

const ClaimsHeader: FC = () => {
	const { t: tClientLabels } = useTranslate(TRANSLATION_KEYS.CLIENT_LABELS);
	const { t: tDbLabels } = useTranslate(TRANSLATION_KEYS.DB_LABELS);

	const { downloadAll } = useSelector((store: RootState) => store.claims);
	const { search, dateOfService, inputClaimStatus, claimsStatuses } =
		useSelector((store: RootState) => store.form);

	const { isDesktop } = useDevice();
	const dispatch = useDispatch<AppDispatch>();

	const handleSelect = (payload: string) => {
		dispatch(handleChangeClaimStatus(payload !== 'All' ? payload : ''));
	};

	const handleCheckDownload = () => {
		if (downloadAll) {
			dispatch(setDownloadAll(false));
			dispatch(setClaimIds([]));
			dispatch(setClaimExcludeIds([]));
		} else {
			dispatch(setDownloadAll(true));
		}
	};

	return (
		<Stack
			direction={'row'}
			position="sticky"
			top={0}
			zIndex={10}
			sx={{
				px: 1.25,
				backgroundColor: palette.grey6.main,
				minWidth: !!isDesktop ? '1490px' : '1310px',
				width: '100%',
			}}
		>
			<Stack
				flexGrow={1}
				direction={'row'}
				sx={{ height: 76 }}
				alignItems={'center'}
			>
				<Checkbox
					label=""
					onClick={handleCheckDownload}
					checked={downloadAll}
					sx={{ p: 0 }}
					accessibilitylabel={tClientLabels('claim.labels.selectAllClaims')}
				/>
				<HeaderCellWithSorting
					name="externalClaimId"
					widthBox={!!isDesktop ? 245 : 220}
					widthInput={213}
				/>
				<HeaderCellWithSorting
					name="firstName"
					widthBox={!!isDesktop ? 245 : 220}
					widthInput={213}
					primarySorting="claimId"
				/>
				<HeaderCellWithSorting
					name="lastName"
					widthBox={!!isDesktop ? 245 : 220}
					widthInput={213}
					primarySorting="claimId"
				/>
				<HeaderCellWithSorting
					name="serviceDate"
					widthBox={!!isDesktop ? 336 : 220}
					widthInput={!!isDesktop ? 300 : 220}
					primarySorting="claimId"
				>
					<Box
						sx={{
							width: !!isDesktop ? 300 : 213,
						}}
					>
						<DatePicker
							variant="field"
							label={tClientLabels('forms.header.serviceDate')}
							value={dateOfService}
							onChange={value => {
								dispatch(
									handleChange<any>({
										name: 'dateOfService',
										value: value,
									}),
								);
							}}
						/>
					</Box>
				</HeaderCellWithSorting>
				<HeaderCellWithSorting
					name="claimStatus"
					widthBox={!!isDesktop ? 336 : 220}
					widthInput={!!isDesktop ? 300 : 220}
					primarySorting="externalClaimId"
				>
					<SelectGeneric
						action={(payload: string) => handleSelect(payload)}
						items={[
							{ value: 'All', text: tClientLabels('forms.status.All') },
							...claimsStatuses.map(el => ({
								value: el.name,
								text: tDbLabels(el.label),
							})),
						]}
						name={'claim'}
						sx={{ width: !!isDesktop ? 300 : 220 }}
						initialValue={inputClaimStatus || 'All'}
					/>
				</HeaderCellWithSorting>
			</Stack>
			<Stack
				flexGrow={1}
				width={'100%'}
				alignItems={'end'}
				height={76}
				justifyContent={'center'}
			>
				<IconButton
					onClick={() => dispatch(setSearch(!search))}
					sx={{ ml: !!isDesktop ? 0 : 4.2 }}
					aria-label={tClientLabels('commons.buttons.search')}
				>
					{search ? <SearchHideIcon /> : <SearchIcon />}
				</IconButton>
			</Stack>
		</Stack>
	);
};

export default ClaimsHeader;

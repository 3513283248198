import { createSlice } from '@reduxjs/toolkit';

interface IState {
	claimId: number;
	claimUUID: string;
	open: boolean;
	redirectTo: DELETE_CLAIM_REDIRECT_TO;
}

export enum DELETE_CLAIM_REDIRECT_TO {
	LIST = 'list',
	DETAILS = 'details',
}

export type DeleteClaimMappingPayload = {
	claimId: number;
	claimUUID: string;
	redirectTo: DELETE_CLAIM_REDIRECT_TO;
};

const initialState: IState = {
	claimId: 0,
	claimUUID: '',
	open: false,
	redirectTo: DELETE_CLAIM_REDIRECT_TO.LIST,
};

const DeleteClaimHookSlice = createSlice({
	name: 'hooks-deleteClaim',
	initialState,
	reducers: {
		setDialogDeleteClaimOpen: (state, { payload }) => {
			state.open = payload;
		},
		setInjectionState: (state, { payload }) => {
			state.claimUUID = payload.claimUUID;
			state.claimId = payload.claimId;
			state.redirectTo = payload.redirectTo;
		},
		resetDialogDeleteClaim: () => initialState,
	},
	extraReducers: builder => {},
});

export const {
	resetDialogDeleteClaim,
	setInjectionState,
	setDialogDeleteClaimOpen,
} = DeleteClaimHookSlice.actions;
export default DeleteClaimHookSlice.reducer;

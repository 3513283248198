import { ReactComponent as CloseDialog } from 'assett/icons/close_dialog_icon.svg';
import SelectGeneric from 'components/commons/form/SelectGeneric';
import { deleteClaim, getClaimDetails } from 'feature/claims/claimSlice';
import { getClaims } from 'feature/claims/claimsSlice';
import { DELETE_CLAIM_REDIRECT_TO } from 'feature/hooks/deleteClaimSlice';
import useDialogDeleteClaim from 'hooks/DialogDeleteClaim.hook';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { AppDispatch, RootState } from 'store/store';
import { Box, Button, Dialog, IconButton, Stack, Typography } from 'styles';
import { useTranslate } from 'stylesHooks';
import { TRANSLATION_KEYS } from 'utils/constants';

const DialogDeleteClaim = () => {
	const location = useLocation();
	const navigate = useNavigate();
	const dispatch = useDispatch<AppDispatch>();
	const { t: tDBLabels } = useTranslate(TRANSLATION_KEYS.DB_LABELS);
	const { t: tClientLabels } = useTranslate(TRANSLATION_KEYS.CLIENT_LABELS);
	const deleteClaimDialog = useDialogDeleteClaim();
	const { open, redirectTo, claimId } = useSelector(
		(store: RootState) => store.hooks.deleteClaim,
	);
	const { reasonCodesCanceled } = useSelector((store: RootState) => store.claim);
	const { claimsStatuses } = useSelector((store: RootState) => store.form);

	const [value, setValue] = useState('');

	const translatedReasonCodes = reasonCodesCanceled.map(item => {
		return {
			value: item.code,
			text: tDBLabels(item.label),
		};
	});

	const handleClick = () => {
		// we have to use the 'name' attribute as the statusCode because the BE could not rename it
		const claimStatusCode = claimsStatuses.find(item => item.id === 15)?.name;

		if (!claimStatusCode) {
			return;
		}

		const payload = {
			reasonCode: value,
			statusCode: claimStatusCode.toLowerCase(),
		};

		dispatch(deleteClaim(payload))
			.then(() => {
				if (redirectTo === DELETE_CLAIM_REDIRECT_TO.LIST) {
					dispatch(getClaims({ limit: '8' }));
				}

				if (redirectTo === DELETE_CLAIM_REDIRECT_TO.DETAILS) {
					// if we are inside the check-and-submit process
					// redirect to the claim details view
					if (location.pathname.includes('check-and-submit')) {
						navigate(`/view-claims/${claimId}`);
					} else {
						dispatch(getClaimDetails(String(claimId)));
					}
				}
			})
			.finally(() => {
				handleClose();
			});
	};

	const handleChange = (value: string) => {
		setValue(value);
	};

	const handleClose = () => {
		setValue('');
		deleteClaimDialog.close();
	};

	return (
		<Dialog
			type={''}
			open={open}
			variant={'deleteClaimDialog'}
			title={tClientLabels('dialog.deleteClaim.title')}
			events={false}
			close={
				<IconButton disableRipple onClick={handleClose}>
					<CloseDialog />
				</IconButton>
			}
			actions={<></>}
			PaperProps={{
				sx: {
					width: {
						sm: '450px !important',
					},
					minWidth: {
						sm: '450px !important',
					},
				},
			}}
		>
			<Stack
				gap={{
					sm: '210px',
					md: '200px',
				}}
			>
				<Stack
					gap={{
						sm: '30px',
						md: '40px',
					}}
				>
					<Box>
						<Typography variant="body">
							{tClientLabels('dialog.deleteClaim.content', {
								claim_number: claimId,
							})}
						</Typography>
					</Box>
					<SelectGeneric
						action={handleChange}
						items={translatedReasonCodes}
						name={'reasonCode'}
						sx={{ width: { sm: 270, lg: 300 } }}
						initialValue={value}
					/>
				</Stack>
				<Stack
					direction={'row'}
					gap={{
						sm: '16px',
						md: '20px',
					}}
					justifyContent={'flex-end'}
				>
					<Button
						variant="contained"
						disabled={!value}
						onClick={handleClick}
						sx={{
							justifyContent: 'center',
						}}
					>
						{tClientLabels('dialog.deleteClaims.confirm')}
					</Button>
				</Stack>
			</Stack>
		</Dialog>
	);
};

export default DialogDeleteClaim;

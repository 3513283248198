import { ReactComponent as DeleteOutlineIcon } from 'assett/icons/circle_cancel_icon.svg';
import { Button, IconButton } from 'styles';
import { useTranslate } from 'stylesHooks';
import { TRANSLATION_KEYS } from 'utils/constants';

type Props = {
	iconButton?: boolean;
	onClickCallback: () => void;
};

const ButtonDeleteClaim = ({ iconButton, onClickCallback }: Props) => {
	const { t: tClientLabels } = useTranslate(TRANSLATION_KEYS.CLIENT_LABELS);

	const handleClick = () => {
		onClickCallback();
	};

	return (
		<>
			{iconButton ? (
				<IconButton
					aria-label={tClientLabels('dashboard.buttons.deleteClaim')}
					onClick={handleClick}
				>
					<DeleteOutlineIcon />
				</IconButton>
			) : (
				<Button
					variant="outlined"
					aria-label={tClientLabels('dashboard.buttons.deleteClaim')}
					onClick={handleClick}
				>
					{tClientLabels('dashboard.buttons.deleteClaim')}
				</Button>
			)}
		</>
	);
};

export default ButtonDeleteClaim;

import { Stack, UnstiledLink } from 'styles';

import { ReactComponent as ChevronBreadCrumbIcon } from 'assett/icons/chevron_breadcrumb_icon.svg';
import { useGetClaimStatusProperty } from 'hooks/utils.hook';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { RootState } from 'store/store';
import styled from 'styled-components';
import BreadcrumbsMui from 'styles/breadcrumbs/Breadcrumbs';
import palette from 'styles/theme/Palette';
import Typography from 'styles/typography/Typograpy';
import { useTranslate } from 'stylesHooks';
import { IClaim } from 'types/Claim';
import { TRANSLATION_KEYS } from 'utils/constants';

const ClaimStatusBreadcrumbs = ({ claim }: { claim: IClaim }) => {
	const { t: tDbLabels } = useTranslate(TRANSLATION_KEYS.DB_LABELS);
	const { t: tClientLabels } = useTranslate(TRANSLATION_KEYS.CLIENT_LABELS);
	const navigate = useNavigate();

	const { reasonCodesCanceled, reasonCodesVoid } = useSelector(
		(store: RootState) => store.claim,
	);
	const { getClaimStatus, getClaimReasonCode } = useGetClaimStatusProperty();

	const { features } = useSelector((store: RootState) => store.user);
	const featuresRole = features.map(el => el.code);

	const {
		status: { id },
	} = claim;

	let pathnames: (1 | 3 | 4 | 9 | 5 | 10 | 11 | 12 | 13 | 14 | 15)[] = [
		1, 3, 4, 9, 5,
	]; //this is the normal workflow: pending doc, submitted, approved, sent for payments, paid

	if (id === 13) {
		pathnames = [...pathnames, 13];
	}
	if (id === 14) {
		pathnames = [...pathnames, 13, 14];
	}

	if (id && [2, 7, 8].includes(id)) {
		//this is the rejected/void status. we show it without breadcrumbs

		return (
			<Typography
				variant="body"
				sx={{
					color: 'error',
					textDecoration: 'none',
					fontWeight: '700',
					textUnderlineOffset: 5,
				}}
			>
				{tDbLabels(getClaimStatus(id)?.label)}
			</Typography>
		);
	}

	if (id && [15].includes(id)) {
		const claimStatusText = tDbLabels(getClaimStatus(id)?.label);
		const reasonCodeLabel = [...reasonCodesCanceled, ...reasonCodesVoid].find(
			el => el.code === getClaimReasonCode(),
		)?.label;

		// this is the Cancelled status. we show it without breadcrumbs
		return (
			<Stack direction={'row'} gap={'4px'}>
				<Typography
					variant="body"
					color={'error'}
					sx={{
						fontWeight: '700',
					}}
				>
					{claimStatusText}
				</Typography>
				<Typography variant="body" color={'error'}>
					{tClientLabels('claim.labels.reasonCode', {
						reasonCode: tDbLabels(reasonCodeLabel ? reasonCodeLabel : ''),
					})}
				</Typography>
			</Stack>
		);
	}

	return (
		<BreadcrumbsMui
			separator={<ChevronBreadCrumbIcon />}
			sx={{ marginBottom: 0, marginTop: 0 }}
		>
			{pathnames.map((elStatus, index) => {
				return (
					<Stack key={index}>
						{elStatus === 1 && id === 1 && featuresRole.includes('submit-claims') ? (
							<UnstiledLink
								variant="body"
								onClick={() => {
									navigate(
										`/check-and-submit/${claim.memberFullName}/new-claim?claimId=${claim.claimId}`,
									);
								}}
							>
								<StyledBreadcrumbStep variant="body" $currentStep={id === elStatus}>
									{tDbLabels(getClaimStatus(elStatus)?.label)}
								</StyledBreadcrumbStep>
							</UnstiledLink>
						) : (
							<StyledBreadcrumbStep variant="body" $currentStep={id === elStatus}>
								{tDbLabels(getClaimStatus(elStatus)?.label)}
							</StyledBreadcrumbStep>
						)}
					</Stack>
				);
			})}
		</BreadcrumbsMui>
	);
};

const StyledBreadcrumbStep = styled(Typography)<
	typeof Typography & { $currentStep: boolean }
>`
	color: ${({ $currentStep }) =>
		$currentStep ? palette.primary.main : palette.grey2.main};
	font-weight: ${({ $currentStep }) => ($currentStep ? 700 : 'normal')};
`;

export default ClaimStatusBreadcrumbs;
